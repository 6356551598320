/*-- cookie consent --*/
window.addEventListener('load', function () {
  window.cookieconsent.initialise({
    'palette': {
      'popup': {
        'background': '#434f66',
        'text': '#ffffff'
      },
      'button': {
        'background': '#778cb3',
        'text': '#ffffff'
      }
    },
    'position': 'bottom',
    'theme': 'classic',
    'type': 'opt-in',
    'content': {
      'dismiss': 'Disallow Cookies',
      'allow': 'Allow Cookies',
      'href': 'https://www.fwfrost-engineers.co.uk/privacy-policy'
    },

    onInitialise: function (status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == 'opt-in' && didConsent) {
        // enable cookies
      }
      if (type == 'opt-out' && !didConsent) {
        // disable cookies
      }
    },

    onStatusChange: function (status, chosenBefore) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == 'opt-in' && didConsent) {
        // enable cookies
      }
      if (type == 'opt-out' && !didConsent) {
        // disable cookies
      }
    },

    onRevokeChoice: function () {
      var type = this.options.type;
      if (type == 'opt-in') {
        // disable cookies
      }
      if (type == 'opt-out') {
        // enable cookies
      }
    },
  })
});

/*-- Open all external links in a new window --*/
jQuery(document).ready(function ($) {
  $('a')
    .filter('[href^="http"], [href^="//"]')
    .not('[href*="' + window.location.host + '"]')
    .attr('rel', 'nofollow noopener noreferrer')
    .not('.trusted')
    .attr('target', '_blank');
});


/*-- skip-to-main --*/
$(document).ready(function () {
  $('.skip-link').click(function (event) {
    var skipTo = '#' + this.href.split('#')[1];
    $(skipTo).attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    }).focus();
  });
});


/*-- Aos Call --*/
AOS.init();


/*-- Lazy Loading Images --*/
var lazy = document.getElementsByClassName('lazy');
for (var i = 0; i < lazy.length; i++) {
  lazy[i].src = lazy[i].getAttribute('data-src');
}


/*-- Dropdown Responsive Menu --*/
(function($) { // Begin jQuery
  $(function() { // DOM ready
    // If a link has a dropdown, add sub menu toggle.
    $('nav ul li a:not(:only-child)').click(function(e) {
      $(this).siblings('.nav-dropdown').toggle();
      // Close one dropdown when selecting another
      $('.nav-dropdown').not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown class
    $('html').click(function() {
      $('.nav-dropdown').hide();
    });
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function() {
      $('nav ul').slideToggle();
    });
    // Hamburger to X toggle
    $('#nav-toggle').on('click', function() {
      this.classList.toggle('active');
    });
  }); // end DOM ready
})(jQuery); // end jQuery

$("ul.nav-list li:has(ul.nav-dropdown)").hover(function () {
     $(this).children("a").click(function () {
        return false;
     });
  });

/*-- Contact Form --*/
$(document).ready(function () {

  // Test for placeholder support
  $.support.placeholder = (function () {
    var i = document.createElement('input');
    return 'placeholder' in i;
  })();

  // Hide labels by default if placeholders are supported
  if ($.support.placeholder) {
    $('.form li').each(function () {
      $(this).addClass('js-hide-label');
    });

    // Code for adding/removing classes here
    $('.form li').find('input, textarea').on('keyup blur focus', function (e) {

      // Cache our selectors
      var $this = $(this),
        $parent = $this.parent();

      if (e.type == 'keyup') {
        if ($this.val() == '') {
          $parent.addClass('js-hide-label');
        } else {
          $parent.removeClass('js-hide-label');
        }
      } else if (e.type == 'blur') {
        if ($this.val() == '') {
          $parent.addClass('js-hide-label');
        } else {
          $parent.removeClass('js-hide-label').addClass('js-unhighlight-label');
        }
      } else if (e.type == 'focus') {
        if ($this.val() !== '') {
          $parent.removeClass('js-unhighlight-label');
        }
      }
    });
  }

  // Show labels by default if placeholders are supported
  if ($.support.placeholder) {
    $('.form__consent__list ul li').each(function () {
      $(this).addClass('js-show-label');
    });
  }
});


/*-- Contact Form Disable --*/
$(document).on('ready', function () {
  $('form').each(function () {
    var form = $(this);
    form.find('button[type="submit"]').on('disable', function () {
      $(this).prop('disabled', true)
        .addClass('loading');
    });
    form.find('button[type="submit"]').on('enable', function () {
      $(this).prop('disabled', false)
        .removeClass('loading');
    });
    form.on('submit', function (e) {
      form.find('button[type="submit"]').trigger('disable');
      /**
       * If submission is via AJAX:
       */
      $.ajax({
        url: form.prop('action'),
        success: function (response) {
          form.find('button[type="submit"]').trigger('enable');
        },
        error: function (response) {
          form.find('button[type="submit"]').trigger('enable');
        }
      });
    });
  });
}); 